import React from 'react';
import './App.css';
import FirstSection from './components/FirstSection';
import SecondSection from './components/SecondSection';
import ThirdSection from './components/ThirdSection';
import FourthSection from './components/FourthSection';
import Footer from './components/layouts/Footer';
import Navbar from './components/layouts/Navbar';
import { isMobile } from 'react-device-detect';
import MobileFirstSection from './components/MobileFirstSection';
import MobileSecondSection from './components/MobileSecondSection';
import MobileThirdSection from './components/MobileThirdSection';
import MobileFourthSection from './components/MobileFourthSection';
import MobileFooter from './components/layouts/MobileFooter';
import ComingSoonSectionBg from './assets/images/comingsoon-section-bg.png';
import MobileComingSoonSectionBg from './assets/images/mobile/m-comingsoon-section-bg.png';


function App() {
  return (
    <>
      {isMobile ? (
        <div>
          <MobileFirstSection />
          <section className="w-full h-full flex justify-center items-center">
            <img className="w-full object-cover" src={MobileComingSoonSectionBg} alt="COMING SOON" />
          </section>
          <MobileSecondSection />
          <MobileThirdSection />
          <MobileFourthSection />
          <MobileFooter />
        </div>
      ) : (
        <div className="App min-w-[1000px]">
          <Navbar />
          <FirstSection />
          <section className="w-full h-full flex justify-center items-center">
            <img className="w-full object-cover" src={ComingSoonSectionBg} alt="COMING SOON" />
          </section>
          <SecondSection />
          <ThirdSection />
          <FourthSection />
          <Footer />
        </div >
      )
      }
    </>
  );
}

export default App;
