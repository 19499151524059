import React from 'react'
import ThirdSectionBg from '../assets/images/mobile/m-third-section-bg-with-logo.png';
import CalorieExpImg from '../assets/images/mobile/m-calories.png';
import ChipText from './small_parts/ChipText';

export default function MobileThirdSection() {
  return (
    <section className="w-full h-full flex justify-center items-start text-center">
      <img className="w-full object-cover" src={ThirdSectionBg} alt="Third Section" />
      <div className="absolute justify-center mt-[10%]">
        <img className="w-full max-w-[300px] mx-auto" src={CalorieExpImg} alt="Calorie" />
        <div className="-mt-[120px]">
          <p className="text-[#A33EED] text-xl gothic-a1-black">최소한의 노력, 최고의 효과</p>
          <div className="mt-1">
            <ChipText text="하루 10분" />
            <span className="ml-2 text-[#A33EED] text-xl gothic-a1-black">칼로리 폭격</span>
          </div>
          <p className="m-auto text-base leading-5 gothic-a1-medium mt-4">
            알아서 운동이 되는 최첨단 운동기기와 함께<br />세상에서 가장 효율적으로 칼로리를 불태우세요.
          </p>
        </div>
      </div>
    </section>
  )
}
