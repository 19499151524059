import React from 'react'

type Props = {
  text: string;
}

export default function ChipText({ text }: Props) {
  return (
    <span className="bg-[#A33EED] text-white py-1 px-2 text-xl font-bold rounded-3xl">
      <span className="gothic-a1-black">{text}</span>
    </span>
  )
}
