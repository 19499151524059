import React from 'react'
import MainSectionBg from '../assets/images/main-bg.png';
import MainWordsImg from '../assets/images/main-words.png';
import AppleIcon from '../assets/images/apple-icon.png';
import GoogleIcon from '../assets/images/google-icon.png';

export default function FirstSection() {
  return (
    <section className="w-full h-full flex justify-center items-center">
      <img className="w-full object-cover" src={MainSectionBg} alt="Main Section" />
      <div className="absolute">
        <img src={MainWordsImg} className="w-full main-img mx-auto" alt="Main" />
        <div className="flex flex-row justify-center items-center mt-5">
          <a href="https://apps.apple.com/us/app/popprika/id1575480079" target='_blank' rel="noreferrer">
            <div className="bg-white rounded-xl flex flex-row justify-center items-center w-[260px] h-[68px] cursor-pointer">
              <img className="w-[34px] h-[40px]" src={AppleIcon} alt="Apple Icon" />
              <p className="gothic-a1-medium text-black text-xl ml-3">App Store</p>
            </div>
          </a>
          <div className="w-4"></div>
          <a href="https://play.google.com/store/apps/details?id=fit.paprica.mobile" target='_blank' rel="noreferrer">
            <div className="bg-white rounded-xl flex flex-row justify-center items-center w-[260px] h-[68px] cursor-pointer">
              <img className="w-[36px] h-[38px]" src={GoogleIcon} alt="Apple Icon" />
              <p className="gothic-a1-medium text-black text-xl ml-3">Google Play</p>
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}
