import React, { useEffect, useState } from 'react'
import { ReactComponent as LogoWhite } from '../../assets/images/b52-logo-white.svg';
import { ReactComponent as LogoPurple } from '../../assets/images/b52-logo-purple.svg';

export default function Navbar() {
  const [isWhiteLogo, setIsWhiteLogo] = useState<boolean>(true);

  const changeLogo = () => {
    if (window.scrollY > 1000) {
      setIsWhiteLogo(false);
    } else {
      setIsWhiteLogo(true);
    }
  }

  useEffect(() => {
    changeLogo();
    window.addEventListener("scroll", changeLogo);
  })

  return (
    <header className="fixed w-full h-[148px]">
      <nav className="mx-auto flex w-full h-full px-[100px] max-w-[1920px] justify-start items-center" aria-label="Global">
        {isWhiteLogo ? (<LogoWhite />) : (<LogoPurple />)}
      </nav>
    </header>
  )
}
