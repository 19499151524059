import React from 'react'
import { ReactComponent as MobileLogoWhite } from '../../assets/images/mobile/m-footer-b52-logo-white.svg';
import InstaIconImg from '../../assets/images/insta.png';

export default function MobileFooter() {
  return (
    <footer className="bg-[#231F20] py-16 flex flex-col items-center">
      <MobileLogoWhite />
      <div className="text-[#818181] text-center">
        <p className="mt-2 text-base gothic-a1-bold"></p>
        <address className="mt-20 text-base gothic-a1-medium">
          (주)비오이 <br />
          사업자등록번호 : 742-87-03136<br />
          전화번호 : 070-7774-7998<br />
          대표이사 : 송재승<br />
          서울특별시 강남구 역삼로 118,<br />
          1층 샵 씨 (역삼동)
        </address>
      </div>
      <p className="mt-40 text-base text-white gothic-a1-bold">FOLLOW US</p>
      <a href="https://www.instagram.com/b52_official" target='_blank' rel="noreferrer">
        <img src={InstaIconImg} className="w-[34px] h-[34px] mt-2" alt="Instagram" />
      </a>
      <p className="text-base text-white gothic-a1-medium mt-40">© B52 Inc.</p>
    </footer >
  )
}
