import React from 'react'
import FourthSectionBg from '../assets/images/mobile/m-fourth-section-bg-with-logo.png';

export default function MobileFourthSection() {
  return (
    <section className="w-full h-full flex justify-center items-end text-center pb-12">
      <img className="w-full object-cover" src={FourthSectionBg} alt="Fourth Section - Price" />
      <div className="absolute mb-16">
        <p className="text-[#A33EED] text-xl gothic-a1-black">PT 1회 절반도 안되는 가격,</p>
        <div className="mt-1">
          <span className="ml-2 text-[#A33EED] text-xl gothic-a1-black">연중무휴 24시간 이용 가능!</span>
        </div>
        <p className="mt-2 text-base gothic-a1-medium">장기권 No! 월 구독상품으로<br />약정없이 언제든지 해지 가능합니다.</p>
      </div>
    </section>
  )
}
