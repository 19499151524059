import React from 'react'
import AppleIcon from '../../assets/images/apple-icon.png';
import GoogleIcon from '../../assets/images/google-icon.png';

type Props = {
  url: string;
  os: "ios" | "android"
}

export default function MobileStoreButton({ url, os }: Props) {
  return (
    <a href={url} target='_blank' rel="noreferrer">
      <div className="bg-white rounded-xl flex flex-row justify-center items-center cursor-pointer py-3 px-6">
        {os === "ios" && (
          <>
            <img className="w-[20px] h-[24px]" src={AppleIcon} alt="Apple Icon" />
            <p className="gothic-a1-medium text-black text-base ml-3">App Store</p>
          </>
        )}
        {os === "android" && (
          <>
            <img className="w-[22px] h-[24px]" src={GoogleIcon} alt="Apple Icon" />
            <p className="gothic-a1-medium text-black text-base ml-3">Google Play</p>
          </>
        )}
      </div>
    </a>
  )
}
