import React from 'react'
import InstaIconImg from '../../assets/images/insta.png';
import LogoWhiteImg from '../../assets/images/b52-logo-white.png';

export default function Footer() {
  return (
    <footer className="bg-[#231F20] py-16 w-full">
      <div className="mx-auto w-full px-[100px] max-w-[1920px] flex felx-row justify-between">
        <div className="text-[#818181] text-left">
          <img src={LogoWhiteImg} alt="B52 Logo white" />
          <p className="text-4xl gothic-a1-bold mt-5">(주)비오이</p>

          <address className="text-base gothic-a1-medium mt-8">
            사업자등록번호 : 742-87-03136<br />
            전화번호 : 070-7774-7998<br />
            대표이사 : 송재승<br />
            서울특별시 강남구 역삼로 118, 1층 샵 씨 (역삼동)<br />
          </address>

          <p className="text-base text-white gothic-a1-medium mt-10">© B52 Inc.</p>
        </div>
        <div className="text-left">
          <p className="text-base text-white gothic-a1-bold">FOLLOW US</p>
          <a href="https://www.instagram.com/b52_official" target='_blank' rel="noreferrer">
            <img className="w-[50px] h-[50px] mt-2" src={InstaIconImg} alt="Instagram" />
          </a>
        </div>
      </div>
    </footer>
  )
}
