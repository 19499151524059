import React from 'react'
import MobileFirstSectionBg from '../assets/images/mobile/m-first-section-bg-with-logo.png';
import MainWordsImg from '../assets/images/main-words.png';
import MobileStoreButton from './layouts/MobileStoreButton';

export default function MobileFirstSection() {
  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 340;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);


  return (
    <section className="w-full h-full flex justify-center items-center">
      <img className="w-full object-cover" src={MobileFirstSectionBg} alt="Main Section" />
      <div className="absolute">
        <img src={MainWordsImg} className="w-full main-img mx-auto" alt="Main" />
        {width < breakpoint ? (
          <div className="flex flex-col w-[200px] m-auto">
            <MobileStoreButton url="https://apps.apple.com/us/app/popprika/id1575480079" os="ios" />
            <div className="h-1"></div>
            <MobileStoreButton url="https://play.google.com/store/apps/details?id=fit.paprica.mobile" os="android" />
          </div>
        ) : (
          <div className="flex flex-row justify-center items-center mt-5">
            <MobileStoreButton url="https://apps.apple.com/us/app/popprika/id1575480079" os="ios" />
            <div className="w-4"></div>
            <MobileStoreButton url="https://play.google.com/store/apps/details?id=fit.paprica.mobile" os="android" />
          </div>
        )}
      </div>
    </section>
  )
}
