import React from 'react'
import FourthSectionBg from '../assets/images/fourth-section-bg.png';

export default function FourthSection() {
  return (
    <section className="w-full h-full flex justify-center items-center pt-30">
      <img className="w-full object-cover" src={FourthSectionBg} alt="Fourth Section - Price" />
      <div className="absolute w-[600px] h-[288px] fourth-section-bg">
        <p className="text-[#A33EED] custom-text-5xl gothic-a1-black">PT 1회 절반도 안되는 가격,</p>
        {/* <div className="mt-5">
          <span className="bg-[#A33EED] text-white py-2 px-5 custom-text-5xl font-bold rounded-3xl">월 29,000원</span>
        </div> */}
        <p className="mt-7 text-[#A33EED] custom-text-5xl gothic-a1-black">연중무휴 24시간 이용 가능!</p>
        <p className="custom-text-3xl gothic-a1-medium mt-7">장기권 No! 월 구독상품으로<br />약정없이 언제든지 해지 가능합니다.</p>
      </div>
    </section>
  )
}
