import React from 'react'
import SecondSectionBg from '../assets/images/mobile/m-second-section-bg-with-logo.png';

export default function MobileSecondSection() {
  return (
    <section className="w-full h-full flex justify-center items-start text-center">
      <img className="w-full object-cover" src={SecondSectionBg} alt="Second Section" />
      <div className="absolute m-second-section-words mt-[15%]">
        <p className="p-2">
          <span className="bg-[#A33EED] text-white py-2 px-3 text-lg font-bold rounded-3xl oswald">RIDE THE MUSIC, PLAY THE BIKE</span>
        </p>
        <p className="text-[#A33EED] text-xl gothic-a1-black mt-5">운동을 더욱 신나게!</p>
        <p className="max-w-[340px] text-base gothic-a1-medium mt-1">몰입감 넘치는 영상 콘텐츠와<br />감각적인 음악과 함께 건강한 일탈을 즐겨보세요.</p>
      </div>
    </section>
  )
}
